import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ChosenCarLabel } from 'components/ChosenCarLabel';

import { Route } from '@/constants/routes';
import { Car } from '@/types';
import { sendGTMEvent } from '@/utils';

type Props = {
  isSelected?: boolean;
  car: Car;
  onSelect: (car: Car) => void;
};

export const CarCardButton = ({ isSelected, car, onSelect }: Props) => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleClick = () => sendGTMEvent("car_selected", {
     car_id: car.id,
  })

  const handleSelectCar = () => {
    handleClick()
    onSelect(car)
  }

  if (router.route === Route.Home) {
    return (
      <Link
        className="block px-6 py-3 mt-4 text-base font-bold text-white transition cursor-pointer primary-color-bg md:px-12 w-fit rounded-xl md:mt-8 md:text-lg hover:-translate-y-1"
        href={`${Route.Car}/${car.slug}`}
        onClick={handleClick}
        prefetch={false}
        role="button">
        {t('details')}
      </Link>
    );
  }

  if (isSelected) {
    return (
      <div className="py-1 mt-4 md:mt-8">
        <ChosenCarLabel />
      </div>
    );
  }

  return (
    <button
      className="block px-6 py-3 mt-4 text-base font-bold text-white transition cursor-pointer primary-color-bg md:px-12 w-fit rounded-xl md:mt-8 md:text-lg hover:-translate-y-1"
      onClick={handleSelectCar}
      type="button">
      {t('selectThisCar')}
    </button>
  );
};
